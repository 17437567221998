import React from 'react';

import FormProvider from './context';

import loadComponents from '../Loadable';

const FormLayout = loadComponents('form-layout');

export default function Form({
  action,
  name,
  fields,
  style,
  children,
  debug = false,
}) {
  const formProps = { name, fields, style };
  return (
    <FormProvider action={action} debug={debug}>
      <FormLayout {...formProps} />
      {children}
    </FormProvider>
  );
}
